<template>
	<div
		class="wrap__content"
		v-bind:class="{
			'wrap__content--preloader': !_getRegionList || !_getPondList || !application,
		}"
	>
		<v-preloader
			v-if="!_getRegionList || !_getPondList || !application"
			:message="errorMessage"
		></v-preloader>

		<div class="container" v-if="_getRegionList && _getPondList && application">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['PowerReservoirManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.power_reservoir') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Наименование</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															:value="application.name"
															disabled
															:placeholder="$t('headers.name')"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Пользователь</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															:value="
																userValue.first_name +
																' ' +
																userValue.last_name +
																' ' +
																userValue.middle_name +
																'\(' +
																userValue.iin_bin +
																')'
															"
															disabled
															placeholder="Пользователь"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Регион</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															:value="regionValue.values[0].value"
															disabled
															:placeholder="$t('headers.name')"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('inputs.pond_name') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															:value="pondValue.values[0].value"
															disabled
															:placeholder="$t('headers.name')"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Файл</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input form__block--input">
														<a
															:href="urlApi + fileDocuments.file"
															class="form__line--file form__line--link"
															target="_blank"
															download="download"
														>
															<div class="form__file form__file--center">
																<div class="form__file--icon">
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 16.2A4.5 4.5 0 0017.5 8h-1.8A7 7 0 104 14.9M12 12v9M8 17l4 4 4-4" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																</div>
																<div class="form__file--text">Скачать файл</div>
															</div>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { api, urlApi } from '@/boot/axios'

export default {
	data() {
		return {
			urlApi: urlApi,
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			application: null,

			regionValue: null,

			pondValue: null,

			userValue: null,

			fileDocuments: {
				file: null,
				fileName: '',
				fileClass: '',
			},
		}
	},
	watch: {
		applicationsList: {
			handler() {
				this.dataItem()
			},
		},
	},
	computed: {
		...mapGetters(['_getRegionList', '_getPondList']),
		applicationsList() {
			const { application, _getRegionList, _getPondList } = this
			return { application, _getRegionList, _getPondList }
		},
	},
	methods: {
		...mapActions(['_fetchRegionList', '_fetchPondList']),
		dataItem() {
			if (this.application && this._getRegionList && this._getPondList) {
				this.regionValue = this._getRegionList.find((element) => {
					if (element.id == this.application.region_id) {
						return element
					}
				})
				this.pondValue = this._getPondList.find((element) => {
					if (element.id == this.application.pond_id) {
						return element
					}
				})
			}
		},
		handleFileUpload() {
			this.message.status = null
			this.message.text = null
			this.message.texts = null
			this.errorMessage = {
				status: null,
				text: null,
				messages: null,
			}
			this.fileDocuments.file = this.$refs.fileDocuments.files[0]
			this.fileDocuments.fileName = this.$refs.fileDocuments.files[0].name
			this.fileDocuments.fileClass = 'add_tourist--success'
		},
		onSubmit(evt) {
			this.message.status = null
			this.message.text = null
			this.message.texts = null

			const formData = new FormData(evt.target)
			if (this.regionValue) {
				formData.append('region_id', this.regionValue.id) //this.pondModel.id
			}
			if (this.pondValue) {
				formData.append('pond_id', this.pondValue.id) //this.pondModel.id
			}
			if (this.userValue) {
				formData.append('user_id', this.userValue.id) //this.pondModel.id
			}
			formData.append('document', this.fileDocuments.file)
			api
				.post('power-reservoir/' + this.$route.params.id, formData)
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.$router.push({
							path: '/' + this.$i18n.locale + '/account/power-reservoir/' + response.data.data.id,
						})
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},
		apiGetPower() {
			api
				.get('power-reservoir/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.application = response.data

						this.userValue = this.application.user
						this.fileDocuments.file = this.application.document
						this.fileDocuments.fileName = this.application.document
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	beforeMount() {
		this.apiGetPower()
		this._fetchPondList()
		this._fetchRegionList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>
